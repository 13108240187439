import store from "@/store";
import { getInfo } from "@/api/api";
import { clearSession } from "@/utils/tools";

const user = {
  state: {
    id: "",
    username: "",
    userinfo: "",
    roles:[],
    power:sessionStorage.getItem('POWER') ? JSON.parse(sessionStorage.getItem('POWER')) : "",
    list:sessionStorage.getItem('USERLIST') ? JSON.parse(sessionStorage.getItem('USERLIST')) : "",
  },

  mutations: {
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
    },
    SET_INFO: (state, userinfo) => {
      state.userinfo = userinfo;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_POWER: (state, power) => {
      state.power = power;
      sessionStorage.setItem('POWER', JSON.stringify(power))
    },
    SET_LIST: (state, list) => {
      state.list = list;
      sessionStorage.setItem('USERLIST', JSON.stringify(list))
    },
  },

  actions: {
    setPower({ commit }, val) {
      commit("SET_POWER", val);
    },
    setList({ commit }, val) {
      commit("SET_LIST", val);
    },
    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((result) => {
            const respData = result;
            sessionStorage.setItem('ddid', respData.user.dingUserId)
            commit("SET_ID", respData.user.userId);
            commit("SET_USERNAME", respData.user.nickName);
            commit("SET_INFO", respData.user);
            commit("SET_ROLES", respData.roles);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_ID", "");
        commit("SET_USERNAME", "");
        commit("SET_ROLES", "");
        clearSession();
        resolve();
      });
    },
  },
};

export default user;
