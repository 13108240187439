const load = {
    state: {
        loading: false
    },
    mutations: {
        showLoading(state){
            state.loading = true
        },
        hideLoading (state) {
            state.loading = false
        }
    }
};

export default load;
