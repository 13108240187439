/////////////////////永久存储////////////////////////
export const setForLocal = (key, value) => {
  localStorage.setItem(key, value);
};
export const getForLocal = (key) => {
  return localStorage.getItem(key);
};
export const removeForLocal = (key) => {
  localStorage.removeItem(key);
};
export const clearLocal = () => {
  localStorage.clear();
};
/////////////////////临时存储////////////////////////
export const setForSession = (key, value) => {
  if (!value) return;
  sessionStorage.setItem(key, value);
};
export const getForSession = (key) => {
  return sessionStorage.getItem(key);
};
export const removeForSession = (key) => {
  sessionStorage.removeItem(key);
};
export const clearSession = () => {
  sessionStorage.clear();
};

export function getParams(organizeCode) {
  let search = window.location.search.replace(/^\?/, "");
  let pairs = search.split("&");
  let paramsMap = pairs.map(pair => {
    let [key, value] = pair.split("=");
    return [decodeURIComponent(key), decodeURIComponent(value)];
  }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap[organizeCode] || "";
}
//处理手机号
export function mobileHide(num) {
  if (num) {
    var reg = /(\d{3})\d{4}(\d{4})/;
    var tel = num.replace(reg, "$1****$2");
    return tel
  }else{
    return num
  }

}
export function nameHide(name) {
  if (name && name.length > 1) {
    let strAry = name.split('');
    strAry[1] = "*";
    return strAry.join('');
  } else {
    return name
  }

}

//防抖
export function debounce(fn, wait) {
  let timeout = null;
  wait = wait || 600;
  return function () {
    let that = this;
    if(timeout !== null)   clearTimeout(timeout);  
    timeout = setTimeout(() => {
      fn.apply(that);
    }, wait);
  }    
}