import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../login/LoginPage";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "login",
    meta: {},
    component: LoginPage,
    // component: () => import("../views/index/index.vue"),
  },
  {
    path: "/check-user",
    name: "check-user",
    component: () => import("../login/CheckUser"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      keepAlive: true
    },
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/home-page",
    name: "home-page",
    component: () => import("../views/home-page/index.vue"),
  },
  {
    path: "/sop-detail",
    name: "sop-detail",
    component: () => import("../views/sop/detail.vue"),
  },
  {
    path: "/sop-add",
    name: "sop-add",
    component: () => import("../views/sop/add.vue"),
  },
  {
    path: "/plan-add",
    name: "plan-add",
    component: () => import("../views/follow/add.vue"),
  },
  {
    path: "/sutra",
    name: "sutra",
    component: () => import("../views/sutra/index.vue"),
  },
  
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404/index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index"),
  },
  {
    path: "/authentication",
    name: "authentication",
    component: () => import("../views/authentication/index"),
  },
  {
    path: "/association",
    name: "association",
    component: () => import("../views/association/index"),
  },
  {
    path: "/reservation",
    name: "reservation",
    component: () => import("../views/reservation/index"),
  },
  {
    path: "/chose-campus",
    name: "ChoseCampus",
    component: () => import("../views/reservation/process/choseCampus.vue"),
  },
  {
    path: "/campus-form",
    name: "CampusForm",
    component: () => import("../views/reservation/process/campusForm.vue"),
  },
  
  
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
