import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./router/navigation-guards";

import store from "./store";
import "./assets/styles/index.css";
import "./assets/styles/index.less";
import "amfe-flexible";
import Vant from "vant";
import "vant/lib/index.css";
// import eruda from 'eruda';

// if (process.env.NODE_ENV === "development") {
//   eruda.init();
// }

Vue.config.productionTip = false;
Vue.use(Vant);
Vue.filter("formatMoney", (num) => {
  var res = num.toString().replace(/\d+/, function (n) {
    // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ",";
    });
  });
  return res;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
