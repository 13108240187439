<script>
export default {
  mounted() {
    const appid  = "ww6b930b228cff8ab8"
    const agentid = "1000031"
    let url = window.location.origin + "/check-user" + window.location.search;
    // let url = "http://127.0.0.1:9002" + "/check-user" + window.location.search;
    console.log('url ===>', url)

    //https://open.weixin.qq.com/connect/oauth2/authorize?appid=CORPID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_base&state=STATE&agentid=AGENTID#wechat_redirect

    // window.location.href =  window.location.origin + "/check-user" +"?code=" +  "jxSqWXHIuuHRvcROvCWDvTETVzBNrq3uD2gijC0Pqx8"

    // return

    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?" +
      "redirect_uri=" +
      encodeURIComponent(url) +
      "&response_type=code" +
      "&appid=" +
      appid +
      "&scope=snsapi_base" +
      "&state=STATE" +
      "&agentid="+
      agentid+
      "#wechat_redirect";
  },
};
</script>

<template>
  <div></div>
</template>

<style scoped lang="less"></style>
