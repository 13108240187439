import router from "./index";
import store from "../store";
import { getToken } from "@/utils/token";

// 白名单
const whiteList = ["/", "/check-user",  '/404','/login'];
router.beforeEach((to, from, next) => {
  let hasToken = getToken();
  if (hasToken) {
    if (!store.getters.id) {
      store.dispatch("GetUserInfo").then((user) => {
        next();
      });
    } else {
      next();
    }
  } else {
    //系统白名单
    //判断藏经阁
    console.log("路由",from,to)
    if(to.query.realUrl){
      if(whiteList.includes(to.path)){
        next()
      }else{
        router.push({ path: "/", query: {
          realUrl:"sutra"
        } })
      }
    }else{
      whiteList.includes(to.path) ? next() : next({ path: "/" });
    }
    
  }
});
