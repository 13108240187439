import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/token";
import resultCode from "@/utils/result-code";
import { Toast } from "vant";
import store from "@/store";

NProgress.configure({
  easing: "ease", // 动画方式，和css动画属性一样（默认：ease）
  speed: 500, // 递增进度条的速度，单位ms（默认： 200）
  showSpinner: false, // 是否显示加载ico
  trickle: true, //是否自动递增
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比，0-1（默认：0.08）
});

const ajax = axios.create({
  baseURL: "/prod-api",
});

// 添加请求拦截器
ajax.interceptors.request.use(
  function (config) {
    //请求开始时显示进度条
    NProgress.start();
    store.commit('showLoading')
    config.headers = {
      Authorization: "Bearer " + getToken(),
    };
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    store.commit('hideLoading')
    return Promise.reject(error);
  }
);

// 添加响应拦截器
ajax.interceptors.response.use(
  function (response) {
    //服务响应时完成进度条
    NProgress.done();
    store.commit('hideLoading')
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (process.env.VUE_APP_PATH === "mock") {
      return response.data;
    }
    if (response.data.code !== resultCode.SUCCESS) {
      if (response.data.code === resultCode.FORBIDDEN) {
        if (!(document.getElementsByClassName("van-toast__text").length > 0)) {
          // Toast("令牌失效，即将重新登录");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("code");
          
          setTimeout(() => {
            let rewrite = window.location.pathname, query = window.location.search

            console.log(rewrite,query)
         

            // let str = ''
            // if (query) {
            //   str = '&rewriteUrl='
            // } else {
            //   str = '?rewriteUrl='
            // }
            // window.location.href = location.origin + query + str + rewrite;


            if(query.indexOf("sutra")>-1){
              window.location.href = location.origin + "?realUrl=sutra";
            }else{
              window.location.href = location.origin
            }



          }, 1000);
        }
      } else {
        let errmsg =  response.data.msg? response.data.msg:"服务器错误，请联系管理员"
          // process.env.NODE_ENV === "production"
          //   ? "服务器错误，请联系管理员"
          //   : response.data.msg;
        Toast.fail(errmsg);
      }

      // return Promise.reject(response.data);
    }
    return response.data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    store.commit('hideLoading')
    return Promise.reject(error);
  }
);

export default ajax;
