<template>
  <div id="app">
    <loading v-show="loading"></loading>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import Loading from "./views/loading/index.vue";
export default {
  computed: {
    loading() {
      return this.$store.state.loading.loading;
    },
  },
  created() {
    // var userAgent = navigator.userAgent;
    // if (/(iPhone|iPad|iPod)/i.test(userAgent)) {
    //   // iOS系统
    //   console.log("苹果");
    // } else if (/Android/i.test(userAgent) && /Mobile/.test(userAgent)) {
    //   // Android系统且为移动版本
    //   console.log("安卓");
    // } else if (/Windows Phone/i.test(userAgent)) {
    //   // Windows Phone系统
    //   console.log("Windows Phone");
    // } else {
    // }
    console.log(this.$store.state.loading.loading);
  },
  mounted() {
    var element = document.getElementById("app");
    var width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    // 设置新的宽度和高度值
    if(width){
      console.log("width",width)
      element.style.width = "99.99%";
      element.style.width = "100%";
    }
  },
  name: "App",
  components: {
    Loading,
  },
};
</script>
<style>
</style>
