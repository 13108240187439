import axios from "../utils/axios";

export const getInfo = (params) => {
  return axios({
    url: "/getInfo",
    method: "get",
    params,
  });
};


// 账号密码登录
export const login = (data) => {
  return axios({
    url: "/login",
    method: "post",
    data,
  });
};
//微信登录接口
export const wxLogin = (data) => {
  return axios({
    url: "/wxLogin",
    method: "get",
    params: data,
  });
};

//查看业绩
export const payList = (data) => {
  return axios({
    url: "/work/pa_info/xiaofeiList",
    method: "get",
    params: data,
  });
};

//通过微信ID获取患者信息
export const queryInfo = (data) => {
  return axios({
    url: "/work/pa_info/query-pa-info",
    method: "get",
    params: data,
  });
};

//获取用户微信详情
export const queryUserInfo = (data) => {
  return axios({
    url: "/work/wecom/get-user-info",
    method: "get",
    params: data,
  });
};

//根据微信ID查询用户是否绑定
export const queryBind = (data) => {
  return axios({
    url: "/work/pa_hsp/select-is-bind",
    method: "get",
    params: data,
  });
};

// 企微患者与his患者绑定
export const bindHis = (data) => {
  return axios({
    url: "/work/pa_info/bind-his",
    method: "post",
    data,
  });
};

// 企微患者与his患者绑定
export const rematch = (data) => {
  return axios({
    url: "/work/pa_info/rematch",
    method: "post",
    data,
  });
};

//查询到院记录
export const queryDaoyuanList = (data) => {
  return axios({
    url: "/work/pa_info/daoyuanlist",
    method: "get",
    params: data,
  });
};

//查询患者轨迹
export const queryTrajectoryList = (data) => {
  return axios({
    url: "/work/query-his/trajectory-list",
    method: "get",
    params: data,
  });
};

//查询回访记录列表
export const selectRecordList = (data) => {
  return axios({
    url: "/work/pa_ret/select-record-list",
    method: "get",
    params: data,
  });
};

//目的列表
export const selectObjectiveList = (data) => {
  return axios({
    url: "/base/ret_objective/list",
    method: "get",
    params: data,
  });
};

// 新增回访记录
export const addRecord = (data) => {
  return axios({
    url: "/work/pa_ret/add-record",
    method: "post",
    data,
  });
};

//删除回访记录
export const delRecord= (data) => {
  return axios({
    url: "/work/pa_ret/del-record",
    method: "get",
    params: data,
  });
};


//获取回访记录详细信息
export const getHfDetail= (data) => {
  return axios({
    url: "/work/pa_ret/get-ret-info",
    method: "get",
    params: data,
  });
};

//查询回访计划模板及明细
export const getTemplateList= (data) => {
  return axios({
    url: "/base/plan_template/templateList",
    method: "get",
    params: data,
  });
};

//查询回访计划分类
export const getClassList= (data) => {
  return axios({
    url: "/base/plan_class/list",
    method: "get",
    params: data,
  });
};

//保存回访计划

export const addPaPlanList= (data) => {
  return axios({
    url: "/work/pa_plan/addPaPlanList",
    method: "post",
    data: data,
  });
};

//执行回访记录
export const executeRecord= (data) => {
  return axios({
    url: "/work/pa_ret/execute-record",
    method: "post",
    data: data,
  });
};
//JS-SDK使用权限签名

export const getSignature= (data) => {
  return axios({
    url: "/work/wecom/get-signature",
    method: "get",
    params: data,
  });
};

//JS-SDK应用使用权限签名
export const getAgentSignature= (data) => {
  return axios({
    url: "/work/wecom/get-app-signature",
    method: "get",
    params: data,
  });
};

//查询企微业标签(本地)
export const compLabelList= (data) => {
  return axios({
    url: "/base/label_info/select-root-label-list",
    method: "post",
    data: data,
  });
};

//编辑客户企业标签信息
export const editCompLabel= (data) => {
  return axios({
    url: "/work/pa_label/edit-pa-info-label",
    method: "post",
    data: data,
  });
};

//sop列表
export const selectSopList= (data) => {
  return axios({
    url: "/work/sop/select-pa-sop-list",
    method: "get",
    params: data,
  });
};

//查询sop详情列表
export const selectSopDetails= (data) => {
  return axios({
    url: "/work/sop/select-sop-details-list",
    method: "post",
    data: data,
  });
};

//修改SOP启用 停用状态
export const editStatus= (data) => {
  return axios({
    url: "/work/sop/edit-status",
    method: "post",
    data: data,
  });
};

//查询回访计划一级分类
export const selectOnePlanClass= (data) => {
  return axios({
    url: "/base/plan_class/select-one-plan-class",
    method: "get",
    params: data,
  });
};

//查询回访计划一级分类
export const selectTwoPlanClass= (data) => {
  return axios({
    url: "/base/plan_class/select-two-plan-class",
    method: "get",
    params: data,
  });
};

//添加sop时 列表
export const addSopList= (data) => {
  return axios({
    url: "/work/sop/add-sop-list",
    method: "post",
    data: data,
  });
};

//查询一级话术分类列表
export const selectOneHsClass= (data) => {
  return axios({
    url: "/work/script_class/select-one-class-list",
    method: "get",
    params: data,
  });
};

//查询树形
export const selectTemplateTree= (data) => {
  return axios({
    url: "/work/script_class/select-template-tree",
    method: "post",
    data: data,
  });
};

//添加使用话术记录
export const useLog= (data) => {
  return axios({
    url: "/work/script_log/use-log",
    method: "post",
    data: data,
  });
};

//热门话术
export const popularScript= (data) => {
  return axios({
    url: "/work/script_log/popular-script",
    method: "get",
    params: data,
  });
};

//添加sop列表的 修改状态 （新增sop）
export const addSopStatus= (data) => {
  return axios({
    url: "/work/sop/add-sop-edit-status",
    method: "post",
    data: data,
  });
};

//目的列表
export const selectUnread = (data) => {
  return axios({
    url: "/work/sop_log/select-unread",
    method: "get",
    params: data,
  });
};

//目的列表
export const getDeptList = (data) => {
  return axios({
    url: "/work/aychannel/getDeptList",
    method: "get",
    params: data,
  });
};

//目的列表
export const getHspList = (data) => {
  return axios({
    url: "/work/aychannel/getHspList",
    method: "get",
    params: data,
  });
};

//预约登记
export const addReservation= (data) => {
  return axios({
    url: "/work/aychannel/add",
    method: "post",
    data: data,
  });
};

//查询关联预约平台患者列表

export const selectChannelList= (data) => {
  return axios({
    url: "/work/aychannel/select-channel-list",
    method: "post",
    data: data,
  });
};

//关联预约平台患者
export const addAssociation= (data) => {
  return axios({
    url: "/work/pa_info/association",
    method: "post",
    data: data,
  });
};

//取消关联预约平台患者
export const noAssociation= (data) => {
  return axios({
    url: "/work/pa_info/no-association",
    method: "post",
    data: data,
  });
}; 

//获取用户信息和登记列表
export const getPaByQca = (data) => {
  return axios({
    url: "/work/aychannel/getPaByQca",
    method: "get",
    params: data,
  });
};

//预约登记
export const aychannelAdd= (data) => {
  return axios({
    url: "/work/aychannel/add",
    method: "post",
    data: data,
  });
}; 





 