<template>
  <div class="loading">
    <van-loading class="loadingVant" color="#1989fa"  vertical ></van-loading>
  </div>
</template>
 
<script>
export default {
  name: "loading",
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .loadingVant {
  
  }
}
</style>